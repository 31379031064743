














































































































































































import Vue from "vue";
import config from "../config.json";

export default Vue.extend({
  name: "Home",
  data() {
    return {
      title: "",
      desc: "",
      columns: [
          {
              field: 'location',
              label: 'Location',
          },
          {
              field: 'date',
              label: 'Date',
          },
          {
              field: 'time',
              label: 'Time',
          }
      ],
      locations: [] as { date: string; collections: ({ location: string; times: string[]; mrs: boolean; })[]; }[],
      collections: [] as { date: string; location: string; time: string; mrs: boolean; }[],
      selected: null as { date: string; location: string; time: string; mrs: boolean; } | null,
    };
  },
  created() {

    this.title = config.home.title;
    this.desc = config.home.desc;
    
    this.locations = config.locations;


    // Loop through locations and push collections to collections array in order by date
    this.locations.forEach(location => {
      location.collections.forEach(collection => {

        const debug = false;
        const today = new Date(debug ? "2022-11-29T20:00:00.000Z" : new Date().toISOString());

        const date = new Date(location.date);
        const dateString = new Date(location.date).toLocaleDateString('en-GB', { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' });

        const startTime = new Date(location.date);
        startTime.setHours(Number(collection.times[0].split(':')[0]), Number(collection.times[0].split(':')[1]), Number(collection.times[0].split(':')[2]));
        
        const endTime = new Date(location.date);
        endTime.setHours(Number(collection.times[1].split(':')[0]), Number(collection.times[1].split(':')[1]), Number(collection.times[1].split(':')[2]));

        
        const startString = startTime.toLocaleTimeString(["en-GB"], { hour12: true, hour: 'numeric', minute: 'numeric' });
        const endString = endTime.toLocaleTimeString(["en-GB"], { hour12: true, hour: 'numeric', minute: 'numeric' });
        
        const timeString = `${startString} - ${endString}`;

        // If date has already passed, don't add to collections array
        if (endTime.getTime() < today.getTime()) return;

        this.collections.push({ date: dateString, location: collection.location, time: timeString, mrs: collection.mrs });

        // If today is the same as the date
        if (today.toDateString() == date.toDateString()) {
          if (today.getTime() >= startTime.getTime() && today.getTime() <= endTime.getTime()) {
            this.selected = this.collections[this.collections.length - 1];
          }
        }

      });
    });

  }
});
